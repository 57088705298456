import { get, post } from '../http';

const baseUrl = '/promotionData';
const distributionMemberDeptCountList = (querydistributionMemberDetailListParam) =>
    get(`${baseUrl}/promotionMemberDepartmentCountQueryForSystemC`, querydistributionMemberDetailListParam, true);

const changeDeptDistributionAmount = (deptCode, changeAmount) =>
    post(`${baseUrl}/changeDeptDistributionAmount?deptCode=${deptCode}&changeAmount= ${changeAmount}`, null, false);

const queryDistributionRecordPage = (deptCode, queryParam) =>
    get(`${baseUrl}/deptDistributionRecordPage/${deptCode}`, queryParam, true);

export default {
    baseUrl,
    distributionMemberDeptCountList,
    changeDeptDistributionAmount,
    queryDistributionRecordPage,
};
