import { get } from 'request/http';
import Util from 'js/Util';

const baseUrl = '/system/dept';
const deptTreeUrl = `${baseUrl}/deptTree`;

const managedDeptsByDeptGroupCode = (deptGroupCode = '') => {
    if (Util.isEmpty(deptGroupCode)) {
        return new Promise(() => {
            return [];
        });
    }
    return get(deptTreeUrl, { deptGroupCode: deptGroupCode });
};

const companyDeptGroupRelatedDepts = (deptGroupCode = '') => {
    if (Util.isEmpty(deptGroupCode)) {
        return new Promise(() => {
            return [];
        });
    }
    return get(`${baseUrl}/noneManaged/deptGroupRelatedDepts`, { deptGroupCode: deptGroupCode });
};

const managedDeptsByDeptType = async (deptType = '') => {
    if (Util.isEmpty(deptType)) {
        return new Promise(() => {
            return [];
        });
    }
    const depts = (await get(`${baseUrl}/staffManagedAllDepts`)) || [];
    return depts.filter((e) => {
        return e.type === deptType;
    });
};

const managedAllDepts = () => {
    return get(`${baseUrl}/staffManagedAllDepts`);
};

const managedStoresAndRepos = () => {
    return get(`${baseUrl}/managedStoresAndRepos`);
};

const companyAllRepos = () => {
    return get(`${baseUrl}/noneManaged/companyAllRepos`);
};

const companyAllStores = (excludeInvalidDeptFlag = true) => {
    return get(`${baseUrl}/noneManaged/companyAllStores?excludeInvalidDeptFlag=${excludeInvalidDeptFlag}`);
};

const deptDetail = (code) => {
    if (Util.isEmpty(code)) {
        return new Promise(() => {
            return {};
        });
    }
    return get(`${baseUrl}/info_c/${code}`);
};

export default {
    baseUrl,
    deptTreeUrl,
    managedDeptsByDeptGroupCode,
    companyDeptGroupRelatedDepts,
    managedDeptsByDeptType,
    managedAllDepts,
    managedStoresAndRepos,
    companyAllRepos,
    companyAllStores,
    deptDetail,
};
